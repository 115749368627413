'use client';
import { FC, useEffect, useState } from 'react';
import classes from './NavigationMenu.module.css';
import Link from 'next/link';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { sections, getIndexForPath } from '@/lib/routePositions';
import cx from 'clsx';

interface NavButton {
  label: string;
  href: string;
  icon?: string;
}

interface NavigationMenuProps {
  id?: string;
  additionalButtons?: NavButton[];
}

const NavigationMenu: FC<NavigationMenuProps> = ({
  additionalButtons = [],
  id = 'sidebar',
}) => {
  const path = usePathname();
  const selectedIndex = getIndexForPath(path);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Close menu if path changes
  useEffect(() => {
    setIsMenuOpen(false);
  }, [path]);

  return (
    <aside className={cx(classes.root, { [classes.open]: isMenuOpen })}>
      <nav className={classes.bar}>
        {additionalButtons &&
          additionalButtons.map((button, idx) => (
            <Link
              key={`nav-button-${idx}`}
              className={classes.button}
              href={button.href}>
              {button.icon && (
                <Image src={button.icon} alt="" width={36} height={36} />
              )}
              {button.label}
            </Link>
          ))}
        <Link
          className={classes.button}
          href={`#${id}`}
          onClick={() => setIsMenuOpen(true)}>
          <Image src="/images/icons/menu.svg" alt="" width={36} height={36} />
          Menu
        </Link>
      </nav>

      <nav id={id} className={cx(classes.drawer)} aria-hidden={!isMenuOpen}>
        <header className={classes.header}>
          <h1 className={classes.title}>Index</h1>
          <Link
            className={classes.close}
            href={path}
            onClick={() => setIsMenuOpen(false)}>
            Close
          </Link>
        </header>

        <ul className={classes.list}>
          {sections.map((section, index) => {
            if (section.hidden) {
              return null;
            }
            return (
              <li key={`nav-item-${index}`}>
                {index === selectedIndex ? (
                  <span className={classes.current}>{section.label}</span>
                ) : (
                  <Link className={classes.link} href={section.path}>
                    {section.label}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </aside>
  );
};

export default NavigationMenu;
