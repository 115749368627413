interface NavItem {
  path: string;
  label: string;
  hidden?: boolean;
}

export const sections: NavItem[] = [
  {
    label: 'Landing',
    path: '/',
  },
  {
    label: 'Listen',
    path: '/listen',
  },
  {
    label: 'James Baldwin',
    path: '/quotes/baldwin',
    hidden: true,
  },
  {
    label: 'About & Credits',
    path: '/about',
  },
  {
    label: 'Neologisms',
    path: '/neologisms',
  },
  {
    label: 'Maria Popova',
    path: '/quotes/popova',
    hidden: true,
  },
  {
    label: 'William Shatner',
    path: '/quotes/shatner',
    hidden: true,
  },
  {
    label: 'Explore',
    path: '/explore',
  },
  {
    label: 'Aparna Venkatesan',
    path: '/quotes/venkatesan',
    hidden: true,
  },
  {
    label: 'Zuleyka Zevallos',
    path: '/quotes/zevallos',
    hidden: true,
  },
  {
    label: 'Make Contact',
    path: '/contact',
  },
  {
    label: 'Kelly and Zach Weinersmith',
    path: '/quotes/weinersmith',
    hidden: true,
  },
];

export const getIndexForPath = (path: string): number | undefined => {
  const pathWithoutTrailingSlash =
    path.length > 1 && path.endsWith('/') ? path.slice(0, -1) : path;
  const index = sections.findIndex(
    (section) => pathWithoutTrailingSlash == section.path
  );
  return index === -1 ? undefined : index;
};
